#logo-header-book{
    max-width: 300px;
    min-width: 300px;
}

@media screen and (max-width: 851px) {
    #logo-header-book{
        max-width: 200px;
        min-width: 200px;
        margin: auto;
    }

}



