.footer-container{
    height: 17vh;
    background-color: black;
    display: block;
    border-radius: 2px;
    margin-top: 13%;
    
}

.social-footer{
    width: 50%;
    margin: auto;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-evenly;
    font-size: 3em;
}

.footer-text{
    text-align: center;
    color: white;
    font-family: 'Exo','sans-sherif';
    font-size: 1em;
    margin-top: 0.2%;   
}


@media screen and (max-width: 851px) {
    .social-footer{
        font-size: 2rem;
    }

    .footer-text{
        font-size: 0.7rem;
        margin-top: 2%;
    }
}